.Toastify {

  .Toastify__toast-theme--light {
    border-radius: 10px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.75);
    height: fit-content;
    background: transparent;

    &.Toastify__toast--success {
      border-left: 5px solid var(--primary-bg-color);
      background: $white;

      .Toastify__toast-icon {
        svg {
          fill: var(--primary-bg-color);
        }
      }
    }

    &.Toastify__toast--warning {
      border-left: 5px solid $warning;
      background: $white;

      .Toastify__toast-icon {
        svg {
          fill: $warning;
        }
      }
    }

    &.Toastify__toast--error {
      border-left: 5px solid $danger;
      background: $white;

      .Toastify__toast-icon {
        svg {
          fill: $danger;
        }
      }
    }

    .Toastify__progress-bar-theme--light {
      background-color: #dedada !important;
      height: 3px;
    }
  }
}