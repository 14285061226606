@import "./src/assets/scss/style";
@import "utils/toasters/Toasts";
@import "src/shared/paginations/Paginations";

.page-single {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}


#custom-select2 .css-1ko62if-control, #custom-select2 .css-52vcm7-control {
  border-color: #ededf5;
  min-height: 40px;
}

#basic-datatable_wrapper .row {
  width: 100%;
}

.page-link {
  width: fit-content;
}

.border-none {
  border: none !important;
}

.object-fit-cover {
  object-fit: cover;
}

.cursor-pointer {
  cursor: pointer;
}

.w-40 {
  width: 40%;
}

.ms--2 {
  margin-left: -2rem;
}

.word-break {
  word-break: break-all;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: $primary;
}

input:focus + .slider {
  box-shadow: 0 0 1px $primary;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.auth-pass-inputgroup{
  input[type="text"] + .btn .ri-eye-fill{
    &:before{
      content: "\ecb6";
    }
  }
}
