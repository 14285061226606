/* ###### 3.13 Progress ###### */

.progress {
  height: auto;
  border-radius: 0;
}

.progress-bar {
  border-radius: 3px;
  height: 13px;
  font-size: 12px;
}

.progress-bar-xs {
  height: 5px;
}

.progress-bar-sm {
  height: 8px;
}

.progress-bar-lg {
  height: 15px;
}

.progress {
  display: flex;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: $gray-200;
  border-radius: 3px;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $white;
  text-align: center;
  white-space: nowrap;
  background-color: $primary;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}
 .progress-sm .progress-bar {
 border-radius:1px;
 }