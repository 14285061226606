/* ###### 4.9 Perfect Scrollbar ###### */

.ps {
  overflow: hidden;

  > .ps__rail-y {
    width: 8px;
    background-color:transparent;
    z-index: 10;
    position: absolute;
    left: auto !important;
    right: 0;
    opacity: 0;
    transition: opacity 0.2s;

    > .ps__thumb-y {
      position: absolute;
      border-radius: 0;
      width: 5px;
      left: 4px;
    }
  }

  &.ps--active-y {
    &:hover > .ps__rail-y, &:focus > .ps__rail-y {
      opacity: 1;
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .ps > .ps__rail-y {
    transition: none;
  }
}
.ps__rail-y:hover > .ps__thumb-y,.ps__rail-y:focus > .ps__thumb-y,.ps__rail-y.ps--clicking .ps__thumb-y {
  width: 8px;
}
